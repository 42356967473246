<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main analysisContent" id="analysisContent" ref="analysisContent">
            <span class="a-fw-700 a-c-master title">{{ title }}</span>
            <div class="line"></div>
            <div class="a-flex-rsbc">
                <div class="a-flex-rfsfe">
                    <span class="a-c-master a-fw-700 a-fs-16">收益趋势</span>
                    <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
                </div>
                <le-date-range 
                    ref="dateRangeEarning"
                    @change="earningDateChange"
                    :pickerOptions="pickerOptions" 
                    :minDate.sync="earningStartTime" 
                    :maxDate.sync="earningEndTime" 
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
            </div>
            <le-echarts-line :echartsObj="earningCharts" style="margin-top: 0px !important"></le-echarts-line>
            <div class="a-flex-rsbc a-mt-36">
                <div class="a-flex-rfsfe">
                    <span class="a-c-master a-fw-700 a-fs-16">达标趋势</span>
                </div>
                <le-date-range 
                    ref="dateRangeQualify"
                    @change="qualifyDateChange"
                    :pickerOptions="pickerOptions" 
                    :minDate.sync="qualifyStartTime" 
                    :maxDate.sync="qualifyEndTime" 
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
            </div>
            <le-echarts-line :echartsObj="qualifyCharts" style="margin-top: 0px !important"></le-echarts-line>
        </el-card>
    </div>
</template>

<script>

export default {
    data () {
        return {
            title: '这是一个观察看板分析',//页面头部
            choiceDate: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime()
                    if (maxDate) {
                        this.choiceDate = ''
                    }
                },
                disabledDate: (time) => {
                    if (this.choiceDate) {
                        let one = 31 * 24 * 3600 * 1000
                        const minTime = this.choiceDate - one
                        const maxTime = this.choiceDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }else{
                        return ''
                    }
                },
            },
            earningStartTime: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
            earningEndTime: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
            qualifyStartTime: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
            qualifyEndTime: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
            earningCharts: {
                id: 'earningCharts',
                useMineAxisLabel: true,
                xAxisData: [],
                seriesData0: [],
                seriesData1: [],
                seriesName0: '历史总收益',
                seriesName1: '历史单插槽收益',
                seriesUnit0: '元',
                seriesUnit1: '元',
            },
            qualifyCharts: {
                id: 'qualifyCharts',
                useMineAxisLabel: true,
                xAxisData: [],
                seriesData0: [],
                seriesData1: [],
                seriesName0: '达标站点数',
                seriesName1: '总运营站点数',
                seriesUnit0: '个',
                seriesUnit1: '个',
            },
            observeId: '',
            gradeId: ''
        }
    },
    mounted () {
        this.title = this.$route.query.observeName
        this.observeId = this.$route.query.observeId
        this.gradeId = this.$route.query.gradeId
        this.getEarningCharts()
        this.getQualifyCharts()
    },
    methods:{
        earningDateChange () {
            this.getEarningCharts()
        },
        qualifyDateChange () {
            this,getQualifyCharts()
        },
        getEarningCharts () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getObserveIncomeDay,
                method: "post",
                showLoading: false,
                params: {
                    observeId: this.observeId,
                    gradeId: this.gradeId,
                    startDate: this.earningStartTime,
					endDate: this.earningEndTime,
                }
            }).then(res => {
                let dateArr
                if(res.result.data.length){
                    dateArr = res.result.data
                }else{
                    dateArr = this.$getDay.enumerateDaysBetweenDates(this.earningStartTime,this.earningEndTime).map(item=>{
                        return {
                            countDate: item
                        }
                    })
                }
                let xAxisData = []
                let seriesData0 = []
                let seriesData1 = []
                dateArr.map(item=>{
                    xAxisData.push(item.countDate)
                    seriesData0.push(Number((item.hisAllIncome/100).toFixed(2)))
                    seriesData1.push(Number((item.hisPerSlotIncome/100).toFixed(2)))
                })
                this.earningCharts.xAxisData = xAxisData
                this.earningCharts.seriesData0 = seriesData0
                this.earningCharts.seriesData1 = seriesData1
            })
        },
        getQualifyCharts () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getObserveGradeDay,
                method: "post",
                showLoading: false,
                params: {
                    observeId: this.observeId,
                    gradeId: this.gradeId,
                    startDate: this.qualifyStartTime,
					endDate: this.qualifyEndTime,
                }
            }).then(res => {
                let dateArr
                if(res.result.data.length){
                    dateArr = res.result.data
                }else{
                    dateArr = this.$getDay.enumerateDaysBetweenDates(this.qualifyStartTime,this.qualifyEndTime).map(item=>{
                        return {
                            countDate: item
                        }
                    })
                }
                let xAxisData = []
                let seriesData0 = []
                let seriesData1 = []
                dateArr.map(item=>{
                    xAxisData.push(item.countDate)
                    seriesData0.push(item.passStationNum || 0)
                    seriesData1.push(item.stationNum || 0)
                })
                this.qualifyCharts.xAxisData = xAxisData
                this.qualifyCharts.seriesData0 = seriesData0
                this.qualifyCharts.seriesData1 = seriesData1
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
}
.line{
    width: 100%;
    height: 1px;
    border: 1px solid #EBF0F5;
    margin-top: 21px
}
</style>